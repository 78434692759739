.customLegend {
    border: 1px solid #e3e6fc;
    padding: 2em 0 1em;
    margin-top: 2em;
    position: relative;
    border-radius: 6px;
}

.customLegend legend {
    border: 0;
    background: #fff;
    width: auto;
    transform: translateY(-50%);
    position: absolute;
    top: 0;
    left: 1em;
    padding: 0 0.5em;
}

.change_profile_image {
    position: relative;
    height: 122px;
    width: 122px;
    margin: 0 auto 20px;
    overflow: hidden;
    border-radius: 5%;
}

.change_profile_image .file_upload input {
    position: absolute;
    content: "";
    inset: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.change_profile_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.change_profile_image .file_upload {
    position: absolute;
    content: "";
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px !important;
}
.change_profile_image .file_upload i{
    font-size: 25px !important;

}